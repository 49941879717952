.defaultFooter {
    position: fixed;
    bottom: 0;
    background-image: url("../../resources/chalkboard.jpg");
    width: 100%;
    height: 22px;
    text-align: center;
    color: white;
}

.defaultFooter a {
    color: white;
    text-decoration: none;
}