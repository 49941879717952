.baseBtn {
    background-color: white;
    color: black;
    /*border: 2px solid grey; */
    border: 0;
    /*padding-top: 10px;
    padding-bottom: 10px;*/
    padding: 8px;
    border-radius: 5px;
    margin: 5px;
}


@media (hover: hover) {
    .baseBtn:not([disabled]):hover {
        background-color: rgba(232, 164, 33, 0.9);
        color: white;
    }
}

.selected {
    background-color: rgb(0, 147, 71);
    /*border: 2px solid grey;*/
    color: white;
}

@media (hover: hover) {
    .selected:hover {
        background-color: rgba(0, 200, 71, 0.9) !important;
        color: black;
    }
}

.withDelete {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;
}

.deleteBtn {
    background-color: rgb(255, 0, 0);
    color: white;
    /*border: 2px solid grey;
    border-left: 0;*/
    border: 0;
    padding-right: 15px;
    padding-left: 15px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin: 5px;
    margin-left: 0;
}

@media (hover: hover) {
    .deleteBtn:hover {
        background-color: rgba(255, 118, 118, 0.95);
    }
}

.bordered {
    border: 3px solid grey;
}

.modalBtn div {
    float: left;
    margin-right: 2px;
}

.confirmBtn div {
    float: left;
    margin-right: 2px;
}

.confirmBtn:not([disabled]) {
    background-color: rgb(0, 147, 71);
    border: 3px solid rgb(0, 147, 71);
    color: white;
}
.confirmBtn:disabled {
    background-color: grey;
    color: lightgray;
}

@media (hover: hover) {
    .confirmBtn:not([disabled]):hover {
        background-color: rgb(232, 164, 33);
        border-color: rgb(232, 164, 33);

    }
    .confirmBtn:not([disabled]):hover .confirmIcon {
        background-color: white;
        transform: scale(1.02);
    }
}


.confirmIcon {
    background-color: white;
    mask: url("Confirm.svg");
    width:20px;
    height: 20px;
    border: 0;
    border-radius: 50%;
    margin-top: 2px;
    margin-left: 4px;
}

@media (hover: hover) {
    .confirmIcon:not([disabled]):hover {
        transform: scale(1.02);
    }
}

.confirmIcon:disabled {
    background-color: grey;
}

.bigger {
    transform: scale(1.1);
}

.right {
    float: right;
}

.removeOrderBtn {
    background-color: red;
    color: white;
    border: 0;
    margin: 5px;
}

@media (hover: hover) {
    .removeOrderBtn:hover {
        background-color: #ff7a7a;
    }
}

.successBtn {
    background-color: green;
    color: white;
    border-color: darkgreen;
}

@media (hover: hover) {
    .successBtn:hover {
        background-color: #43aa43 !important;
        border-color: #258d25;
    }
}

.rightAuto {
    margin-right: 20px;
}

.removed {
    text-decoration: line-through;
}