/* Provide sufficient contrast against white background */ 
.white{
    color: white;
}

code {
    color: #E01A76;
}

body {
    background-image: url("resources/chalkboard.jpg");
    color: white;
}

.holiday-image {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.holiday-image img {
    width: 40vw;
}

.centered-image {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.text-irish-highlight {
    color: #d99727;
    font-weight: bold;
}

@media only screen and (min-width: 1200px) {
    .centered-image img {
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
}

@media only screen and (max-width: 1200px) {
    .centered-image img {
        width: 100%;
        height: 150px;
        object-fit: cover;
    }
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.baselayout {
    margin: 0;
    margin-bottom: 50px;
}

/*region OrderPage*/
.configurationSelector {
    margin-bottom: 30px;
}

.defaultIngredientRemover {
    margin-top: 15px;
    margin-bottom: 15px;
}

@media only screen and (min-width: 1200px) {


    .orderPage-container {
        display: flex;
    }

    .orderForm-container {
        width: 70%;
    }

    .bottomBanner-container {
        width: 20%;
        position: fixed;
        margin-top: 50px;
        right: 10%;
        bottom: 5%;
        padding: 20px;
    }
}

@media only screen and (max-width: 1200px) {
    .bottomBanner-container {
        margin-top: 60px;
        margin-bottom: 70px;
        display: flex;
    }

    .bottomBanner-container button {
        margin-top: auto;
        position: fixed;
        right: 10px;
        bottom: 25px;
        height: 50px;
    }

    .orderSummary-sum {
        position: fixed;
        width: 100%;
        bottom: 22px;
        left: 0;
        right: 0;
        padding: 5px;
        background-color: white;
        border-top: 2px solid grey;
        height: 70px;
    }

    .orderSummary-sum * {
        color: black;
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}
@media only screen and (max-width: 350px) and (min-width: 300px) {
    .confirmBtn-text {
        display: none;
    }
    .confirmBtn:before {
        content: "  Fertig"
    }
}

@media only screen and (max-width: 300px) {
    .confirmBtn-text {
        display: none;
    }
}

/*endregion OrderPage*/

.cart-item {
    display: inline;
    width: 100%;
}

.cart-item div button {
    width: 20%;
    display: inline;
}

.deliverForm-top {
    display: flex;
    flex-wrap: wrap;
}

.deliverForm-top > *{
    margin-top: 20px;
}

.deliverForm-radio .radioWrapper {
    margin-left: 20px;
}
@media only screen and (min-width: 1200px) {
    .deliverForm-radio {
        flex-basis: 30%;
    }
    .react-datepicker-wrapper {
        flex: 70%;
    }
    
    .deliverForm-datepicker {
        width: 70%;
        margin-right: 0;
    }
}





@media only screen and (max-width: 1200px) {
    .deliverForm-radio {
        flex: 100%;
    }
    .deliverForm-top > *{
        flex: 100%;
    }
}


.centered {
    width: 100%;
    text-align: center;
}

.orderPriceBottom {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    position: fixed;
    bottom: 22px;
    left: 0;
    right: 0;
    padding: 5px;
    color: black;
    background-color: white;
    border-top: 1px solid white;
    height: 70px;
    z-index: 20;
}

.orderPriceBottom button {
    height: 50px;
}

.orderPriceBottom .left {
    padding: 0;
    margin: 0;
    margin-left: 15px;
    margin-top: 8px;
    flex: 0 1 auto;
    font-size: 2em;
    font-weight: bold;
}
.orderPriceBottom .right {
    flex: 0 1 auto;
    margin-left: auto;
}

ul {
    list-style: none;
    margin: 0;
}

.dark {
    color: black;
}

.social-button {
    margin: 5px;
    width: 50px;
    height: 50px;
    display: inline;
}

@media only screen and (max-width: 500px) {
    .long-modal-body-scrollable {
        height: 75vh;
        overflow-y: scroll;
    }
}

.PhoneInput input {
    border: none;
}